'use strict';
var cite = null;

$(document).ready(function () {
  cite = function (window, document, $) {
    var $win = $(window),
    $dom = $(document),
    $body = jQuery(document.body),
    app = {},
    // $firstSlider = $('.first-slider'),
    $customerSlider = $('.customer-slider'),
    $sliderReviews = $('.slider-reviews'),
    $sliderPoorLubrication = $('.slider-poorLubrication'),
    $sliderCardDetails = $('.details-list'),
    $selectBrands = $('.select2.brands'),
    $selectOptions = $('.select2.options'),
    $select = $('.select2'),
    $preloader = $('.preloader'),
    $crossover = $('._cardCategory')


    var fn = {
      //----------slick----------------//
      // initFirstSlider: function initFirstSlider() {
      //   $firstSlider.slick({
      //     dots: true,
      //     arrows: false,
      //     infinite: true,
      //     speed: 400,
      //     fade: true,
      //     cssEase: 'linear',
      //     autoplay: true,
      //     autoplaySpeed: 5000,
      //     lazyLoad: 'ondemand',
      //   });
      // },
      initCustomerSlider: function initCustomerSlider() {
        $customerSlider.slick({
          dots: false,
          arrows: true,
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          infinite: true,
          responsive: [
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        })
      },
      initSliderReviews: function initSliderReviews() {
        let $status = $('.pagingInfo');
        $sliderReviews.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          let i = (currentSlide ? currentSlide : 0) + 1;
          $status.text(i + ' / ' + slick.slideCount);
        });
      
        $sliderReviews.slick({
          arrows: true,
          prevArrow:'.back',
          nextArrow:'.next',
          infinite: true,
          speed: 400,
          fade: true,
          cssEase: 'linear',
          lazyLoad: 'ondemand'
        });
      },
      initSliderPoorLubrication: function initSliderPoorLubrication() {
        let $status = $('.pagingInfo');
        $sliderPoorLubrication.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          let i = (currentSlide ? currentSlide : 0) + 1;
          $status.text(i + ' / ' + slick.slideCount);
        });
      
        $sliderPoorLubrication.slick({
          arrows: true,
          prevArrow:'.back',
          nextArrow:'.next',
          infinite: true,
          speed: 400,
          fade: true,
          cssEase: 'linear',
          lazyLoad: 'ondemand'
        });
      },
      initCategorySlider: function initCategorySlider() {
        $sliderCardDetails.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          // if(currentSlide < slick.slideCount){
          //   console.log('Вперёд')
          // }else{
          //   console.log(currentSlide)
          // }
          
          
        });
        // window.addEventListener("resize", function() {
        //   if (window.innerWidth <= 768) { 
            $sliderCardDetails.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                }
              ]
            });
        //   }
        // })
      
      },
      //----------chozen----------------//
      
      initSearchSelect: function initSearchSelect() {
        
        let url = document.location.protocol + '//' + document.location.host + '/brand'
        let urlUpdate = document.location.protocol + '//' + document.location.host + '/values?'
        let urloptions = document.location.protocol + '//' + document.location.host + '/options/'
        let ifValBrand = $('.select2.brands option:selected').val()
        let ifValOption = $('.select2.options option:selected').val()
        let fnPreloader = function (item, statusItem) {
          item.addClass('loaded_hiding');
          item.removeClass('loaded')

          if (statusItem == 'success') {
            item.addClass('loaded');
            item.removeClass('loaded_hiding');
          }
        }
        if(ifValBrand.length > 0) {
          let valOptionFilters = []
          for (let i = 0; i < $selectOptions.length; i++) {
            if ($($selectOptions[i]).val()) {
              valOptionFilters.push(`&optionFilters[${$($selectOptions[i]).data('id')}]=${$($selectOptions[i]).val()}`)
            } 
          }
          $.ajax({
            url: url +'?' + valOptionFilters.join(),
            type: "get",
            dataType: 'json',
            beforeSend: function(){
              fnPreloader($('.brand_preloader'))
            },
            success: function(response) {
              $.fn.populate = function () {
                let $this = $(this);

                if($this.val().length > 0){    
                  response.brands = jQuery.grep(response.brands, function(value) {
                    return value.id != $this.val();
                  });
                }
                $(response.brands).each(function () {
                  $this.append('<option value="' + this.id + '">' + this.name + '</option>');
                });  
              }         

              $selectBrands.populate();
              $selectBrands.select2();
              fnPreloader($('.brand_preloader'), 'success')
            }
            
          });
          let urlUpdate = document.location.protocol + '//' + document.location.host + '/values?brandId=' + ifValBrand
          
          for (let i = 0; i < $selectOptions.length; i++) {
            let id = $($selectOptions[i]).data('id');
            ifValOption =  $($selectOptions[i]).val()
            let urlOptionUpdate = valOptionFilters.length ? urlUpdate  + valOptionFilters.join('') + '&optionId=' + id : urlUpdate  + '&optionId=' + id;
            let name = $($selectOptions[i]).data('name')
            if (!ifValOption) {
              $.ajax({
                url: urlOptionUpdate,
                type: "get",
                dataType: 'json',
                beforeSend: function(){
                  fnPreloader( $($preloader[i]) )
                },
                  
                success: function(response) {
                  
                  $.fn.populate = function (name) {
                    let $this = $(this);
                    let valueThis;
          

                    if($this.val().length !== 0){
                      valueThis = $this.val()
                    } 

                    if(response.values.length > 0) {
                      $.each((response.values), function() {   
                        if(valueThis == this) {
                          $this.empty()
                          $this.append('<option value="">' + name + '</option>');
                          $this.append('<option selected value="' + this + '">' + this + '</option>'); 
                        } else {
                          $this.append('<option value="' + this + '">' + this + '</option>'); 
                        }                                 
                                              
                      });
                    } else {
                      $this.append('<option disabled value="">' + 'No results found' + '</option>');
                    }
                  }
                  $($selectOptions[i]).populate(name);
                  fnPreloader( $($preloader[i]), 'success' )
                
                }
              });
            } else {  
              $.ajax({
                url: urlUpdate  + '&optionId=' + id,
                type: "get",
                dataType: 'json',
                beforeSend: function(){
                  fnPreloader( $($preloader[i]) )
                },
                success: function(response) {

                  $.fn.populate = function () {
                    let $this = $(this);

                    let valueThis;
          

                    if($this.val().length !== 0){
                      valueThis = $this.val()
                    } 

                    if(response.values.length > 0) {
                      $.each((response.values), function() {   
                        if(valueThis == this) {
                          $this.empty()
                          $this.append('<option value="">' + name + '</option>');
                          $this.append('<option selected value="' + this + '">' + this + '</option>'); 
                        } else {
                          $this.append('<option value="' + this + '">' + this + '</option>'); 
                        }                                 
                                              
                      });
                    } else {
                      $this.append('<option disabled value="">' + 'No results found' + '</option>');
                    }
                  }
                
                  $($selectOptions[i]).populate();
                  $($selectOptions[i]).select2()
                  fnPreloader( $($preloader[i]), 'success' )
                
                }
              });          
                
            }
          
          }
        } else {
          if(ifValOption.length == 0) {
            $.ajax({
              url: url,
              type: "get",
              dataType: 'json',
              beforeSend: function(){
                fnPreloader($('.brand_preloader'))
              },
              success: function(response) {
                $.fn.populate = function () {
                  let $this = $(this);

                  if($this.val().length > 0){    

                    response.brands = jQuery.grep(response.brands, function(value) {
                      return value.id != $this.val();
                    });
                
                
                    // let urlUpdate = document.location.protocol + '//' + document.location.host + '/values?brandId=' + $this.val()
                    // let valOptionFilters = []
                    // for (let i = 0; i < $selectOptions.length; i++) {
                    //   if ($($selectOptions[i]).val()) {
                    //     valOptionFilters.push(`&optionFilters[${$($selectOptions[i]).data('id')}]=${$($selectOptions[i]).val()}`)
                    //   } 
                    // }
                    // for (let i = 0; i < $selectOptions.length; i++) {
                    //   let id = $($selectOptions[i]).data('id');
                    //   ifValOption =  $($selectOptions[i]).val()
                    //   let urlOptionUpdate = valOptionFilters.length ? urlUpdate  + valOptionFilters.join('') + '&optionId=' + id : urlUpdate  + '&optionId=' + id;
                  
                    //   // let urlOptionUpdate = ifValOption ? urlUpdate  + '&optionFilters[' + ifValOption + ']' + '&optionId=' + id : urlUpdate  + '&optionId=' + id;
                    //   let name = $($selectOptions[i]).data('name')
                      
                    //   $.ajax({
                    //     url: urlOptionUpdate,
                    //     type: "get",
                    //     dataType: 'json',
                    //     beforeSend: function(){
                    //       fnPreloader( $($preloader[i]) )
                    //     },
                          
                    //     success: function(response) {
                          
                    //       $.fn.populate = function (name) {
                    //         let $this = $(this);
                    //         ifValOption = $this.val()
        
                    //         if(!ifValOption) {
                    //             $($this).empty();
                    //             $this.append('<option value="">' + name + '</option>');
                    //         } else {
                    //           $($this).empty();
                    //           $this.append('<option value="">' + name + '</option>');
                    //           $this.append('<option selected value="' + ifValOption + '">' + ifValOption + '</option>');
                    //         }
                            
                
                    //         if(response.values.length > 0) {
                    //           $.each((response), function() {
        
                    //             $.each((this), function() {  
                                
                    //                 $this.append('<option value="' + this + '">' + this + '</option>');
                              
                                    
                    //             })
                                
                    //           });
                    //         } else {
                    //           $this.append('<option disabled value="">' + 'No results found' + '</option>');
                    //         }
                        
                          
                    //       }
                    //       $($selectOptions[i]).populate(name);
                    //       fnPreloader( $($preloader[i]), 'success' )
                        
                    //     }
                    //   });
                    // }
                  }
                  $(response.brands).each(function () {
                    $this.append('<option value="' + this.id + '">' + this.name + '</option>');
                  });  
                }         

                $selectBrands.populate();
                $selectBrands.select2();
                fnPreloader($('.brand_preloader'), 'success')
              }
              
            });
          }
        }

        if(ifValOption.length > 0) {
          
          let getOption = []
          let valOptionFilters = []
          for (let i = 0; i < $selectOptions.length; i++) {
            if ($($selectOptions[i]).val()) {
              valOptionFilters.push(`&optionFilters[${$($selectOptions[i]).data('id')}]=${$($selectOptions[i]).val()}`)
            } 
          }
          if (ifValBrand.length > 0) {
            getOption.push('brandId=' + ifValBrand)
          } 
          getOption.push(valOptionFilters.join(''))
          for (let i = 0; i < $selectOptions.length; i++) {    
            let id = $($selectOptions[i]).data('id');
            ifValOption = $($selectOptions[i]).val();
            let name = $($selectOptions[i]).data('name');
           
            if (!ifValOption) {
              $.ajax({
                url: urlUpdate + getOption + '&optionId=' + id,
                type: "get",
                dataType: 'json',
                beforeSend: function(){
                  fnPreloader( $($preloader[i]) )
                },
                success: function(response) {
                  
                  $.fn.populate = function () {
                    let $this = $(this);
                    let valueThis;
        

                    if($this.val().length !== 0){
                      valueThis = $this.val()
                    } 

                    if(response.values.length > 0) {
                      $.each((response.values), function() {   
                        if(valueThis == this) {
                          $this.empty()
                          $this.append('<option value="">' + name + '</option>');
                          $this.append('<option selected value="' + this + '">' + this + '</option>'); 
                        } else {
                          $this.append('<option value="' + this + '">' + this + '</option>'); 
                        }                                 
                                              
                      });
                    } else {
                      $this.append('<option disabled value="">' + 'No results found' + '</option>');
                    }
                  }                 
                  $($selectOptions[i]).populate();
                  fnPreloader( $($preloader[i]), 'success' )
                }
            }); 
            } else {
              for (let i = 0; i < $selectOptions.length; i++) {

                let id = $($selectOptions[i]).data('id');
                let urloption = urloptions + id
                ifValOption =  $($selectOptions[i]).val()
                
                $.ajax({
                  url: urloption,
                  type: "get",
                  dataType: 'json',
                  beforeSend: function(){
                    fnPreloader( $($preloader[i]) )
                  },
                  success: function(response) {
  
                    $.fn.populate = function () {
                      let $this = $(this);
  
                      $.each((response), function() {
  
                        if($this.val().length !== 0){
                          this.raw_values = jQuery.grep(this.raw_values, function(value) {
                            return value != $this.val();
                          });
                        } 
                        $.each((this.raw_values), function() {
                          $this.append('<option value="' + this + '">' + this + '</option>');
                        })
                        
                      });
                    
                    }
                  
                    $($selectOptions[i]).populate();
                    $($selectOptions[i]).select2()
                    fnPreloader( $($preloader[i]), 'success' )
                  
                  }
                });          
              }
            }
                        
          }
          $.ajax({
            url: url + '?' + getOption,
            type: "get",
            dataType: 'json',
            beforeSend: function(){   
              fnPreloader( $('.brand_preloader') )
            },             
            success: function(response) {

              $.fn.populate = function () {
                let $this = $(this);                

                if($this.val() !== 0){
                  response.brands = jQuery.grep(response.brands, function(value) {
                    return value.id != $this.val();
                  }); 
                } 
                if(response.brands.length > 0) {
                  
                  $.each((response.brands), function() {
                      $this.append('<option value="' + this.id + '">' + this.name + '</option>');
                  });
                } else {
                  $this.append('<option disabled value="">' + 'No results found' + '</option>');
                }
              }         
  
              $selectBrands.populate();  
              fnPreloader( $('.brand_preloader'), 'success' )
            }     
          }); 
        } else {
          if(ifValBrand.length == 0) {
            for (let i = 0; i < $selectOptions.length; i++) {

              let id = $($selectOptions[i]).data('id');
              let urloption = urloptions + id
              ifValOption =  $($selectOptions[i]).val()
              
              $.ajax({
                url: urloption,
                type: "get",
                dataType: 'json',
                beforeSend: function(){
                  fnPreloader( $($preloader[i]) )
                },
                success: function(response) {

                  $.fn.populate = function () {
                    let $this = $(this);

                    $.each((response), function() {

                      if($this.val().length !== 0){
                        this.raw_values = jQuery.grep(this.raw_values, function(value) {
                          return value != $this.val();
                        });
                      } 
                      $.each((this.raw_values), function() {
                        $this.append('<option value="' + this + '">' + this + '</option>');
                      })
                      
                    });
                  
                  }
                
                  $($selectOptions[i]).populate();
                  $($selectOptions[i]).select2()
                  fnPreloader( $($preloader[i]), 'success' )
                
                }
              });          
            }
          }
        }

       

        if (true) {
          $selectBrands.select2().on('change', function(e){

            let eventBrandId = e.target.value;
            let urlUpdate = document.location.protocol + '//' + document.location.host + '/values?brandId=' + eventBrandId
            let valOptionFilters = []

            // ifValBrand = $('.select2.brands option:selected').val()
            for (let i = 0; i < $selectOptions.length; i++) {
              if ($($selectOptions[i]).val()) {
                valOptionFilters.push(`&optionFilters[${$($selectOptions[i]).data('id')}]=${$($selectOptions[i]).val()}`)
              }
              
            }
            
            for (let i = 0; i < $selectOptions.length; i++) {
              let id = $($selectOptions[i]).data('id');
              ifValOption =  $($selectOptions[i]).val()
              let urlOptionUpdate = valOptionFilters.length ? urlUpdate  + valOptionFilters.join('') + '&optionId=' + id : urlUpdate  + '&optionId=' + id;
              let name = $($selectOptions[i]).data('name')
              if (ifValOption) {
                urlOptionUpdate = urlUpdate  + '&optionId=' + id
              }
              $.ajax({
                url: urlOptionUpdate,
                type: "get",
                dataType: 'json',
                beforeSend: function(){
                  fnPreloader( $($preloader[i]) )
                },
                  
                success: function(response) {
                  
                  $.fn.populate = function (name) {
                    let $this = $(this);
                    let valueThis;
        

                    if($this.val().length !== 0){
                      valueThis = $this.val()
                     } 
                    $this.empty()
                    $this.append('<option value="">' + name + '</option>');

                   
                    if(response.values.length > 0) {
                      $.each((response.values), function() {   
                        if(valueThis == this) {
                          $this.append('<option selected value="' + this + '">' + this + '</option>'); 
                        } else {
                          $this.append('<option value="' + this + '">' + this + '</option>'); 
                        }                                 
                                              
                      });
                    } else {
                      $this.append('<option disabled value="">' + 'No results found' + '</option>');
                    }
                  }
                  $($selectOptions[i]).populate(name);
                  fnPreloader( $($preloader[i]), 'success' )
                 
                }
              });
            }
  
          });
  
          let getEventValue = []
          let arrEventId = []
          let getOption = []
      
          for (let i = 0; i < $selectOptions.length; i++) {
            
            $($selectOptions[i]).select2().on('change', function(e) {
              ifValBrand = $('.select2.brands option:selected').val()
              let ifNameBrand = $('.select2.brands option:selected').text()
       

              if (ifValBrand.length > 0) {
                getOption.push('brandId=' + ifValBrand)
              } 
            
              // let eventValue = (e.target.value).split(' ').join('');
              let eventValue = (e.target.value)

              getEventValue.push(eventValue)
              
              let eventId = $(e.target).data('id')
              let thisValue
              let idx = arrEventId.indexOf( eventId )
              
              if (jQuery.inArray( eventId, arrEventId) == -1 ) {
                arrEventId.push(eventId)
                thisValue = `optionFilters[${eventId}]=${eventValue}`
                getOption.push(thisValue)
              } else {
                getOption[idx] = `optionFilters[${eventId}]=${eventValue}`
              }
              
              if (eventValue == $($selectOptions[i]).data('name')) {
                getOption.splice(idx, 1);
              } 
  
              if (getOption.length == 0) {
                // console.log('getOption ' + getOption)
                for (let i = 0; i < $selectOptions.length; i++) {
  
                  let id = $($selectOptions[i]).data('id');
                  let urloption = urloptions + id
                  let name = $($selectOptions[i]).data('name')
                  $.ajax({
                    url: urloption,
                    type: "get",
                    dataType: 'json',
                    beforeSend: function(){
                      fnPreloader( $($preloader[i]) )
                    },
                      
                    success: function(response) {
                      
                      $.fn.populate = function () {
                        let $this = $(this);
                        let valueThis;
        

                        if($this.val().length !== 0){
                          valueThis = $this.val()
                        } 
    
                        if(response.values.length > 0) {
                          $.each((response.values), function() {   
                            if(valueThis == this) {
                              $this.empty()
                              $this.append('<option value="">' + name + '</option>');
                              $this.append('<option selected value="' + this + '">' + this + '</option>'); 
                            } else {
                              $this.append('<option value="' + this + '">' + this + '</option>'); 
                            }                                 
                                                  
                          });
                        } else {
                          $this.append('<option disabled value="">' + 'No results found' + '</option>');
                        }
                        // $this.empty()
                        // $this.append('<option value="">' + name + '</option>');

                        // if($this.val().length !== 0){
                        //   // console.log($this.val())
                        //   this.raw_values = jQuery.grep(this.raw_values, function(value) {
                        //     return value != $this.val();
                        //   });
                        // } 
                        // $.each((response), function() {
                    
                        //   $.each((this.raw_values), function() {
                        //     $this.append('<option value="' + this + '">' + this + '</option>');
                        //   })
                          
                        // });
                      
                      }
                    
                      $($selectOptions[i]).populate();
                      fnPreloader( $($preloader[i]), 'success' )
                    }
                  });          
                }

              } else {
      
                let urlOptionUpdate = urlUpdate + getOption.join('&')
                for (let y = 0; y < $selectOptions.length; y++) {
                  if ( y !== i) {
                    let id = $($selectOptions[y]).data('id');
                    let getThisUrl = getOption.length == 0 ? urlOptionUpdate + id : urlOptionUpdate + '&optionId=' + id;
                    // console.log(getThisUrl)
                    let name = $($selectOptions[y]).data('name')
                    ifValOption =  $($selectOptions[i]).val()

                    $.ajax({
                      url: getThisUrl,
                      type: "get",
                      dataType: 'json',
                      beforeSend: function(){
                        fnPreloader( $($preloader[y]) )
                      },
                      success: function(response) {
                        
                        $.fn.populate = function (name) {
                          let $this = $(this);
                          ifValOption = $this.val()
                          if(!ifValOption) {
                            $($this).empty();
                            $this.append('<option value="">' + name + '</option>');
                        } else {
                          $($this).empty();
                          $this.append('<option value="">' + name + '</option>');
                          $this.append('<option selected value="' + ifValOption + '">' + ifValOption + '</option>');
                        }
                      
  
                          // console.log(response.values)
                          if((response.values).length > 0 ) {
                            $.each($(response.values), function() {
  
                                  $this.append('<option value="' + this + '">' + this + '</option>');
                              
                            });
                          } else {
                            $this.append('<option disabled value="">' + 'No results found' + '</option>');
                          }
                      
                        
                        }
                        
                        $($selectOptions[y]).populate(name);
                        fnPreloader( $($preloader[y]), 'success' )
                      }
                    });  
                  }          
                }
                $.ajax({
                  url: url + '?' + getOption.join('&'),
                  type: "get",
                  dataType: 'json',
                  beforeSend: function(){   
                    fnPreloader( $('.brand_preloader') )
                  },
                    
                  success: function(response) {
                    $('.brand_preloader').removeClass('loaded_hiding')
                    $.fn.populate = function () {
                      let $this = $(this);
                      
                      $($this).empty();
                      $this.append('<option value="">Brand</option>');

                     

                      if($this.val() !== 0){
                        response.brands = jQuery.grep(response.brands, function(value) {
                          return value.id != $this.val();
                        });
        
                      } 
                      if(response.brands.length > 0) {
                       
                        $.each((response.brands), function() {
                          if(ifValBrand == this.id) {
                            $this.append('<option selected value="' + ifValBrand + '">' + ifNameBrand + '</option>');
                          } else {
                            $this.append('<option value="' + this.id + '">' + this.name + '</option>');
                          }
                          
                        });
                      } else {
                        $this.append('<option disabled value="">' + 'No results found' + '</option>');
                      }
                    }         
        
                    $selectBrands.populate();  
                    fnPreloader( $('.brand_preloader'), 'success' )
                  }     
                }); 
              }
    
            });
            
          }
          $('#clear').on('click', function() {
         
            $.ajax({
              url: url,
              type: "get",
              dataType: 'json',
              beforeSend: function(){     
                fnPreloader( $('.brand_preloader') )
              },
              success: function(response) {
                $.fn.populate = function () {
                  let $this = $(this);
                  $($this).empty();
                  $this.append('<option value="">Brand</option>');

                  $(response.brands).each(function () {
                    $this.append('<option value="' + this.id + '">' + this.name + '</option>');
                  });  
                }         
    
                $selectBrands.populate(); 
                fnPreloader( $('.brand_preloader'), 'success' )
              }             
            });
         
            for (let i = 0; i < $selectOptions.length; i++) {
    
              let id = $($selectOptions[i]).data('id');
              let urloption = urloptions + id
    
              $.ajax({
                url: urloption,
                type: "get",
                dataType: 'json',
                beforeSend: function(){     
                  fnPreloader( $($preloader[i]) )
                },
                success: function(response) {
                let name = $($selectOptions[i]).data('name')
    
                  $.fn.populate = function () {
                    let $this = $(this);
    
                    $($this).empty();
                    $this.append('<option value="">' + name + '</option>');
                    $.each((response), function() {
                      $.each((this.raw_values), function() {  
                        $this.append('<option value="' + this + '">' + this + '</option>');
                      })
                      
                    });
                  
                  }
                
                  $($selectOptions[i]).populate();
                  fnPreloader( $($preloader[i]), 'success' )
                }
              });          
            }
          })
        }

        // $('#clear').on('click', function() {
        //   $select.val(null).trigger("change");
        // })
      },

      //----------crossover----------------//
      initCrossoverCards: function initCrossoverCards() {
        
        let alis = $('#alias').val()  
        let route = $('.analogues input[type=hidden]').val()  
        let url = document.location.protocol + '//' + document.location.host + '/motor/' + alis + '/analogue'
        let flagAnalogue = $('#has_crossovers').val()
            // console.log(flagAnalogue)
        if (flagAnalogue == 0) {
          $.ajax({
            url: url,
            type: "get",
            dataType: 'json',
            success: function(response) {
              // console.log(response.analogues)
              if($(response.analogues).length == 0){
                let noMotors = $('<p/>').html('No motors').appendTo($('.analogues .wrapp-cards'))
              }
              let wrapp = $('.analogues .wrapp-cards')
             
              $.each(response.analogues, function (index, value) {
                  if (index < 4) {
                    
                    var card = $('<div/>', {
                      'class': '_card'
                    }).appendTo(wrapp);
  
                    var title = $('<div/>', {
                      'class': 'title-card title-ellipsis'
                    }).html(this.brand_name).appendTo(card);
  
                    var span = $('<span/>').html(this.name).appendTo(title)
  
                    var ill = $('<div/>', {
                      'class': 'illustration'
                    }).appendTo(card);
                    let picture = $('<picture/>').appendTo(ill);
  
                    let imgSrc = this.media_value_with_main_picture !== null ? this.media_value_with_main_picture.full_file_name : '../img/MotorSearch/TOSH_ECO_IEC.jpg'
                    let img = $('<img>', {
                      'src' : imgSrc
                    }).appendTo(picture)
                    //-----------------description-motor-----------
                    let description = $('<ul/>', {
                      'class' : 'description-motor'
                    }).appendTo(card)
                    let name
                    if (this.option_values[0].option_name == 'Category') {
                      name = this.option_values[0].values
                    } else [
                      name = "-"
                    ]
                    let category = $('<li>').html('<span> Category :</span>' + ' ' + name).appendTo(description) 
                    //------------------parametrs----------------------
                    let param = $('<ul/>', {
                      'class' : 'parametrs'
                    }).appendTo(card)
                    $(this.option_values).each(function (key, value) {
                      if (this.option_is_in_card_website !== false && this.option_name !== 'Category') {
                          let li = $('<li/>', {                            
                          }).html('<span>' + this.option_name + ' :</span>' + this.values).appendTo(param)
                      }                    
                    })
                    //-----------documentation-------------
                    let documentation = $('<div/>', {
                      'class': 'documentation'
                    }).appendTo(card)
                    if (this.media_values.length > 0) {
                      $(this.media_values).each(function (key, value) {
                        if (this.media_is_main == true) {
                          let doc = $('<a/>', {
                            'href': this.full_file_name 
                          }).html('<span class="icon-Group5"></span>' + this.media_name).appendTo(documentation)
                        }
                      })
                    }
                    
                    //------------footer--------------------
                    let footer = $('<div/>', {
                      'class': 'footer'
                    }).appendTo(card)
  
                    let details = $('<a/>', {
                      'target' : '_blank',
                      'href' : this.route_show,
                      'class' : 'btn'
                    }).html('Details').appendTo(footer)
       
                    let compare = $('<div/>', {
                      'class':  test.includes(this.id.toString()) ? 'active btn-grey' : 'btn-grey',
                      'data-name': this.id
                    }).html('<span class="icon-Shape-2"></span>').appendTo(footer)
  
                    
  
                  }  else {
                    return false;    
                  }   
                });  
  
              let allCrossovers = $('<a/>', {
                'href' : route,
                'class': 'btn btn-crossovers'
              }).html('All Crossovers').appendTo($('.analogues'))   
  
              $('.analogues_preloader').addClass('loaded_hiding');
                window.setTimeout(function () {
                  $('.analogues_preloader').addClass('loaded');
                  $('.analogues_preloader').removeClass('loaded_hiding');
                  
              }, 500);
  
              activedCompare()
              
  
              
            }
          });
        }

      },
      domReady: function domReardy(cb) {
        // if ($firstSlider.length) fn.initFirstSlider();
        if ($customerSlider.length) fn.initCustomerSlider();
        if ($sliderReviews.length) fn.initSliderReviews();
        if ($sliderPoorLubrication.length) fn.initSliderPoorLubrication();
        if ($sliderCardDetails.length) fn.initCategorySlider();
        if ($selectBrands.length) fn.initSearchSelect();
        if ($crossover.length) fn.initCrossoverCards();
        if (cb) {
					cb();
				}
      }
    }

    $dom.ready(function () {
			return fn.domReady(fn.onDocLoad);
		});
    return fn;

  }(window, document, jQuery);
//------------Cookies----------------------------
  let compare2 = readCookie('compare') || '' 
  let test = compare2 ? compare2.split(',') : []
  let toolBar = document.querySelector('.toolBar')
  let btnClose = document.querySelector('.clear.btn')

  function writeCookie(name, val, expires) {
    var date = new Date;
    date.setDate(date.getDate() + expires);
    document.cookie = name+"="+val+"; path=/; expires=" + date.toUTCString();
  }
  function readCookie(name) {
    var matches = document.cookie.match(new RegExp(
      "[?:^|; ]" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  if(toolBar !== null) {
    if (test.length > 0) {
      toolBar.classList.add('active')
    } else {
      toolBar.classList.remove('active')
    }
    btnClose.onclick = function() { 
      writeCookie('compare', [], 1)
      test = []
      $('.btn-grey').removeClass('active')
      toolBar.classList.remove('active')
    }
  }

  
//----------mobMenu-------// 
  function activedNavBtn() {
    let navBtn =  document.querySelector('.nav-btn')
    let wrappMenu = document.querySelector('.menu')

    navBtn.onclick = function() {   
        wrappMenu.classList.toggle('active')
        document.body.classList.toggle('overflow')
    };
    if(document.documentElement.clientWidth < 981) {
      
        let dropBlock = document.querySelectorAll('.drop-block')

        dropBlock.forEach(el => {
          el.onclick = function() {
            el.classList.toggle('active') 
          }
        });
       
    }     
  }
//----------btnCompare------//
  function activedCompare() {
    let compareBtn = document.querySelectorAll('.btn-grey')

    let compare2 = readCookie('compare') || '' 
    let test = compare2 ? compare2.split(',') : []

    let compare = (e) => {

      if( test.includes(e.currentTarget.getAttribute('data-name')) == false ) {
        e.currentTarget.classList.add("active")
        test.push(e.currentTarget.getAttribute('data-name'))
        writeCookie('compare', test, 1)
        test = readCookie('compare').split(',')
        if (test.length > 0) {
          toolBar.classList.add('active')
        } else {
          toolBar.classList.remove('active')
        }
      } else {

        test = test.filter(function(f) { return f !== e.currentTarget.getAttribute('data-name') })
        e.currentTarget.classList.remove("active")
        writeCookie('compare', test, 1)
   
        // console.log(test)
        if (test.length < 1) {
           toolBar.classList.remove('active')
        }


      }
    }

    for(let iterator = 0; iterator < compareBtn.length; iterator++ ){
      compareBtn[iterator].addEventListener("click", compare, false)
    }
    
  };
//----------alert-success------//
  function activedAlertPopup() {
    let alertPopup = document.querySelector('.alert-success')
    let cross = document.getElementById('alertClose')

  
    if(alertPopup.classList.contains('active')){
      document.body.classList.add('overflow')
    }
   
    cross.onclick = function() {   
      alertPopup.classList.remove('active')
      document.body.classList.remove('overflow')
    };

    
  }
  //----------alert-success------//
  function activedPopup() {
    let popup = document.querySelector('.popup')

    let cross = document.getElementById('alertClose')

    if(popup.classList.contains('active')){
      document.body.classList.add('overflow')
    }
    
    cross.onclick = function() {   
      popup.classList.remove('active')
      document.body.classList.remove('overflow')
    };

    
  }
//----------cookie---------//
  function activedCookie() {
    let cookieBlock = document.querySelector('.cookie-section')
    let btnClose = document.querySelector('.icon-cross')
    let bthYes = document.querySelector('.cookie-section .btn')

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    
    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    
    function checkCookie() {
        var user = getCookie("flag");
        if (user == "") {
          cookieBlock.classList.add('active')
        } 
    }
    if(bthYes) {
      bthYes.onclick = function() {
        setCookie("flag", 'true', 365);
        cookieBlock.classList.remove('active')
      }
    }
    if(btnClose) {
      btnClose.onclick = function() {
        cookieBlock.classList.remove('active')
      }
    }
    
    checkCookie()
  }
//----------анимация-------// 
  function  activedAnimate()  {
    function onEntry(entry) {
        entry.forEach(change => {
          if (change.isIntersecting) {
            change.target.classList.add('element-show')
    
          } else {
            change.target.classList.remove('element-show')
          }
        });
    }
    let options = { threshold: [0.5] }
    let observer = new IntersectionObserver(onEntry, options)
    let elements = document.querySelectorAll('.element-animation')
    
    for (let elm of elements) {
      observer.observe(elm)
    };
  }
//----------search---------//
  function activedSearch() {
    let btn = document.querySelectorAll(".menu-search-btn")
    let dropWrapp = document.querySelector(".dropdown-wrapp")
    let heightFormBlock = document.querySelector(".main-form-container")

    if($('.item-category').length) {
      let itemCategory = document.querySelectorAll(".item-category")
      // $('.cards-search').css('display', 'block') 

      let selectCategory = (e) => {
        for(let iterator = 0; iterator < itemCategory.length; iterator++ ){
          itemCategory[iterator].classList.remove("active")
        }
        e.target.classList.add("active")
  
      }
      for(let iterator = 0; iterator < itemCategory.length; iterator++ ){
        itemCategory[iterator].addEventListener("click", selectCategory, false)
      }  
    }
    //----------tabs-searchMotor-------
    let selectBtn = (e) => {
      for(let i = 0; i < btn.length; i++ ){
        btn[i].classList.remove("active")
      }
      e.target.classList.add("active")

      if(e.target.classList.contains('dropdown')) {
        dropWrapp.classList.add('displayBlock')
        heightFormBlock.style.height = dropWrapp.clientHeight  + 50 + 'px'
        // $('.cards-search').css('display', 'block') 
      } else {
        dropWrapp.classList.remove('displayBlock')
        heightFormBlock.style.height = "auto"
      }
    }
    for(let iterator = 0; iterator < btn.length; iterator++ ){
      btn[iterator].addEventListener("click", selectBtn, false)
    }  

  }
  function initAjaxFn(idForm, url) {
    $(idForm).submit(function(e){
      e.preventDefault();
      var data = $(idForm).serialize();
      $.ajax({
          url: `${window.location.origin}/${url}`,
          type: "POST", 
          dataType: 'json',
          data: data,
          success: function(respons){
              $('.popup p').html(respons.message);
              $('.popup').addClass('active');
              $(`${idForm}  .form-item input`).val('');
              $(`${idForm}  textarea`).val('');
          },
          error: function(error){
              $('.popup p').html(error.responseJSON.message);
              $('.popup').addClass('active')
              $(`${idForm}  .form-item input`).val('');
              $(`${idForm}  textarea`).val('');
          },
      });
      setTimeout(function() { 
          $('.popup').removeClass('active');
      }, 5000);
    });
  }
//-----------shedule-link-----//
  // function activeButtonLink() {
  //   let btn = document.querySelector('.button-link .btn');

  //   btn.onclick = function() {  
  //     // alert('hfthfh')
  //     window.lintrk('track', { conversion_id: 6954212 });
  //     let href = btn.getAttribute('data-href')

  //     window.location = href

  //   }
  // }
//--------contact-form---------//
  function activeForm() {
    
    $( "form" ).submit(function(){
      // alert('hfthfh')
      window.lintrk('track', { conversion_id: 6954220 });
    })
    
  }
//----------------------------//
function activeresumesubmite () {
  if($('#resumeFile:valid').length == 1 && $('.resumeInput:valid').length == 3) {
    $('#apply').addClass('disabled');
  }
}


//----------------------//
  // function activedInputTel() {
  //   jQuery(function($){
  //     $("#phone").mask("9 999 999 9999");
  //  });
  // }
//----------------------//
  if($('#leads').length) {
    initAjaxFn('#leads', 'leads')
  }
  if($('.menu-search-btn').length) {
    activedSearch()
  }
  if($('._card').length) {
    activedCompare()
  }
  if($('.alert-success').length) {
    activedAlertPopup()
  }
  if($('.popup').length) {
    activedPopup()
  }
  if($('#apply').length) {
    $('#apply').click(() => {
      activeresumesubmite();
    })
    
  }
  // if($('.button-link').length) {
  //   activeButtonLink()
  // }
  if($('.form').length) {
    activeForm()
  }
  //---------tabs in card-------//
  if($('.item-details').length) {
    $('.cards-search').css('display', 'block') 

    $('#tabs').each(function() {
      let ths = $(this);
      ths.find('.description-motor').not(':first').hide();
      ths.find('.item-details').click(function() {
        ths.find('.item-details').removeClass('active').eq($(this).index()).addClass('active');
        ths.find('.description-motor').hide().eq($(this).index()).fadeIn()
      }).eq(0).addClass('active');
    });
  }
  if($('.item-filter').length) {

    $('#tabs_vacancies').each(function() {
      let ths = $(this);
      ths.find('.item-filter').click(function() {
        ths.find('.item-filter').removeClass('active').eq($(this).index()).addClass('active');
      }).eq(0).addClass('active');
    });
  }
  $('#resumeFile').change(function() {
    if ($(this).val() != '') $(this).prev().text('selected files: ' + $(this)[0].files.length);
    else $(this).prev().text('upload resume');
});
  
  // if($('input[type="tel"]').length) {
  //   activedInputTel()
  // }
  
  activedCookie()
  activedNavBtn()
  activedAnimate()
})

// if($('.delete_click').length) {
//   $('.delete_click').each(function() {
//       $(this).on( "click", function() {
//           confirm( 'Do you really want to delete?' ); 
//       })
//   });
// }
